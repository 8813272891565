import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

interface AuthContextType {
    isLoggedIn: boolean;
    loginSuccess: () => void;

  }
  
  // 초기 상태를 가진 객체를 제공합니다.
const AuthContext = createContext<AuthContextType>({ 
    isLoggedIn: false,
    loginSuccess: () => {
        console.warn("loginSuccess 함수가 아직 구현되지 않았습니다.");
    },
});
  


export const useAuth =() =>{
    return useContext(AuthContext)
}

export const AuthProvider = ({ children } : any) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    
  const loginSuccess = () => {
    setIsLoggedIn(true);
  };



    // const checkLogin = () =>{
    //     const token = localStorage.getItem('accessToken');
    //     if(!token){
    //         setIsLoggedIn(false)
    //     }
    //     else{
    //         const decoded = jwtDecode(token)
    //         const currentTime = Date.now()/1000
    //         if(!decoded.exp || decoded.exp<currentTime){
    //             localStorage.removeItem("accessToken")
    //             setIsLoggedIn(false)
    //         }
    //         else{
    //             setIsLoggedIn(true)
    //         }
    //     }

    // }
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if(!token){
            setIsLoggedIn(false)
        }
        else{
            const decoded = jwtDecode(token)
            const currentTime = Date.now()/1000
            if(!decoded.exp || decoded.exp<currentTime){
                localStorage.removeItem("accessToken")
                setIsLoggedIn(false)
            }
            else{
                console.log(currentTime)
                console.log(decoded.exp)
                setIsLoggedIn(true)
            }
        }

    }, []);
    return <AuthContext.Provider value={{isLoggedIn, loginSuccess}}>{children}</AuthContext.Provider>;
  };


export const PrivateRoute = () => {
const { isLoggedIn } = useAuth();

return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export const PublicRoute = () => {
const { isLoggedIn } = useAuth();

return !isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};
  
