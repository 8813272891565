import { useState } from "react";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (amount: string) => void; // `onSave` 함수의 타입을 명시적으로 지정
  }
  
export function Modal( {isOpen, onClose, onSave } :ModalProps) {
const [amount, setAmount] = useState('');

const handleSave = () => {
    onSave(amount); // 입력된 금액을 상위 컴포넌트로 전달
    onClose(); // 모달 창 닫기
};

if (!isOpen) return null; // 모달 창이 열려있지 않으면 아무것도 렌더링하지 않음

return (
    <div className="modal-container">
        <div className="price-modal-content">
    <input type="text" value={amount} onChange={e => setAmount(e.target.value)} />
        <div >
            <button onClick={handleSave}>확인</button>
            <button onClick={onClose}>취소</button>
        </div>
    </div>
    </div>
);
}
  