import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Login= () => {
  const [id, setid] = useState('');
  const [password, setPassword] = useState('');

  const [loginFail, setLoginFail] = useState('')

  const { loginSuccess } = useAuth();


  const navigate = useNavigate()

  const handleLogin = async (event:any) => {

    event.preventDefault(); // 폼 제출 기본 이벤트 방지
    try {
      const response = await fetch(`${process.env.REACT_APP_FETCH_URL}/payments/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, password }),
      });
      console.log(Date.now())
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        console.log(data.data.accessToken)
        localStorage.setItem('accessToken', data.data.accessToken);
        localStorage.setItem('userId', id);
        // 로그인 성공 후의 로직 추가. 예: 홈 페이지로 리다이렉트
        loginSuccess()
        navigate("/")
        console.log('로그인 성공:', data);
        // 여기서 로그인 성공 처리를 해주세요. 예: 페이지 리디렉션
      } else {
        setLoginFail("아이디 또는 비밀번호를 잘못 입력하였습니다.")
        console.error("로그인 실패")
        return
      }
    } catch (error) {
      setLoginFail("아이디 또는 비밀번호를 잘못 입력하였습니다.")
      console.error('로그인 에러:', error);
      return
    }

    setLoginFail("")
  };
  return (
    <div className='whole-page'>
    <h1 style={{placeItems : "center", display : "grid", marginBlockEnd : 0}}>로그인</h1>
      <form onSubmit={handleLogin} className='login-middle-page'>
        <div className='field'>
          <label htmlFor="id" className='field-text'>아이디:</label>
          <input
            className='field-input'
            type="id"
            id="id"
            value={id}
            onChange={(e) => setid(e.target.value)}
          />
        </div>
        <div className='field'>
          <label htmlFor="password" className='field-text'>비밀번호:</label>
          <input
            className='field-input'
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {loginFail && (
          <div>{loginFail}</div>
        )}
        <button type="submit" className="button-send" style={{
          marginTop : "3%"
        }}>로그인</button>

      </form>
    </div>
  );
}

export default Login;
