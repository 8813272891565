import { useEffect, useRef, useState } from "react";
import { issuerList } from "./IssuerSelector";
import { Modal } from "./modal";
import Lottie from "react-lottie";
interface Image {
    url : string
    name : string
    image : File
    salesAmout : string
  }
   
interface SubmitButtonProps {
    selectedImages : Image[]
    purchaseManager : string
  }
  
const SubmitButton : React.FC<SubmitButtonProps> =({ 
    selectedImages,
    purchaseManager,

 }) => {
  const [resultImages, setResultImages] = useState([]);
    const [loading, setLoading] = useState(false)
    const [errored, setErrored] = useState(false);

    const [isModalOpen, setModalOpen] = useState(false);
    const modalBackground = useRef<HTMLDivElement | null>(null);

    const [errorMessage, setErrorMessage] = useState('');
  
    const handleButtonClick = (message:string) => {
      // 모달 열기 및 오류 메시지 설정
      setModalOpen(true);
      setErrorMessage(message);
    };
    const handleOpenModal = () => {
      setModalOpen(true);
    };

    const handleCloseModal = () => {
      // 모달 닫기
      setModalOpen(false);
    };
  


    

    const formData = new FormData()

    const sendDataToBackend = async () => {
      if (selectedImages.length<1){
        alert("이미지를 올려주세요!")
        return
      }

      setLoading(true)
    
      const timeoutDuration = 500000
      const userId = localStorage.getItem("userId")
      // 폼 데이터에 이미지 및 데이터 삽입
      try{
          for (let i = 0; i < selectedImages.length; i++) {
            formData.append(`file${i}`, selectedImages[i].image)
            formData.append(`amount${i}`,selectedImages[i].salesAmout)
            console.log(selectedImages[i].salesAmout)
          }
          formData.append('purchaseManager', purchaseManager)
          if(userId){
            formData.append("userId", userId)
          }

      }
      catch(error:any){
        alert("전송 데이터 삽입 중 오류가 발생하였습니다.")
        console.error(error)
      }

      try{
        const response = await Promise.race([
          fetch(`${process.env.REACT_APP_FETCH_URL}/payments/ecouponcheck`, {
            method: 'POST',
            body: formData,
          }),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Request timed out')), timeoutDuration)
          ),
        ]);

        if(response instanceof Response && response.ok){
            alert(`이미지 전송에 성공하였습니다.\n총 등록 갯수 : ${selectedImages.length}`);
        setLoading(false)}
      }
      catch(error : any){
        if (error.message === 'Request timed out'){
          console.error('Timeout Error:', error)
          alert('응답 시간이 초과되었습니다.');
        }
        else if(error.message === "Failed to fetch"){
          console.error(error)
          alert('이미지 전송에 실패하였습니다.');
        }
        else{
          console.error('Unexpected Error:', error);
          alert("알 수 없는 오류가 발생하였습니다.");          
        }
        setLoading(false)
      }
  };

    return (
      <div className="button-field">
          

          <button 
              className="button-send"
              onClick={sendDataToBackend}>
              <span className="button-text">판매하기</span>
          </button>


          

        {isModalOpen && (
          <div className="modal-container">
            <div className="modal-content">
              <span className="close" onClick={handleCloseModal}>&times;</span>
              <p>{errorMessage}</p>
            </div>
          </div>
        )}


        {
          // 로딩중일떄 모달창 띄어주기
          loading && <div>
              <div className={'modal-container'} ref={modalBackground} onClick={e => {
              if (e.target === modalBackground.current) {
                setModalOpen(false);
              }
            }}>
              <div className={'modal-content'}>
                <img src="../../loading.gif" width="100%" height="100%"></img>
              </div>
            </div>

          </div>
        }
    </div>
    )
}
export default SubmitButton