import { useState } from "react";


interface purchaserSelectorProps {
    selectedPurchaser: string;
    setSelectedPurchaser : (newpurchaser: string) => void;
    // selectedPhoneNum: string;
    // setSelectedPhoneNum : (newpurchaser: string) => void;

  }
  


const PurchaserSelector : React.FC<purchaserSelectorProps> =({ 
    selectedPurchaser , 
    setSelectedPurchaser,
    // selectedPhoneNum,
    // setSelectedPhoneNum

}) => {



    const handlePurchaserChange = (event : any) => {
        setSelectedPurchaser(event.target.value);
    };

    // const handlePhoneNumChange = (event : any) => {
    //     setSelectedPhoneNum(event.target.value);
    // };
    

    return (
        <div>
        <div className="field">
            <div className="field-text">판매자</div>
            <input
            className="field-input"
                id="Purchaser"
                type="text"
                onChange={handlePurchaserChange} />
        </div>
        {/* <div className="field">
            <div className="field-text">전화번호</div>
            <input
            className="field-input"
                id="Purchaser"
                type="text"
                onChange={handlePhoneNumChange} />
        </div> */}

        </div>
    );
};

export default PurchaserSelector