// App.js (예시)
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import LoginPage from './pages/Login';
import MainPage from './pages/Main';
import SignupPage from './pages/SignUp';
import AuthRoute from './components/AuthRoutes';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { LoggedIn } from './utils/LoggedIn';
import { AuthProvider, PrivateRoute, PublicRoute, useAuth } from './contexts/AuthContext';
const App = () => {


  // useEffect(() => {

  //   setIsLoggedIn(LoggedIn());
  // }, []);


  // const PrivateRoute= ({ children } :any) => {
  
  //   return isLoggedIn ? children : <Navigate to="/login" />;
  // }
  
  // const PublicRoute = ({ children } :any) => {
  
  //   return !isLoggedIn ? children : <Navigate to="/" />;
  // }
  



  return (
    <div>
        <BrowserRouter>
        <AuthProvider>

        <Routes>
        <Route element={<PublicRoute />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<MainPage />} />
          </Route>
    

        </Routes>
        </AuthProvider>

      </BrowserRouter>

    </div>
  );
};

export default App;