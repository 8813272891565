import { useEffect, useRef, useState } from "react";
import { json } from "stream/consumers";
import "../styles/App.scss"
import { Modal } from "./modal";

interface Image {
  url : string
  name : string
  image : File
}

interface imagesSelectorProps {
  selectedImages : any[]
  setSelectedImages : (newImages : Image[]) => void
  imageNameDict: string[]
  setImageNameDict :(newName : string[]) =>void
}




const ImagesSelector : React.FC<imagesSelectorProps> =({ 
  selectedImages , 
  setSelectedImages ,
  imageNameDict,
  setImageNameDict
   }) => {
    const timeoutDuration = 60000
    const issuerBrand = ["올리브영", "신세계백화점" , "CGV", "롯데시네마"]
    const [upLoading, setUpLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const modalBackground = useRef<HTMLDivElement | null>(null);

    const [imagesOpacity, setImagesOpacity] = useState(selectedImages.map(() => false));
    const [priceList, setpriceList] = useState(selectedImages.map(() => '0'));
    const [counts, setCounts] = useState({});
    const [priceElements, setPriceElements] : any= useState([]);
  
    // 이미지 클릭 핸들러
    const handleImageClick = (index:any) => {
      const newImagesOpacity = [...imagesOpacity];
      newImagesOpacity[index] = !newImagesOpacity[index]; // 클릭된 이미지의 투명도 상태를 토글
      setImagesOpacity(newImagesOpacity); // 상태 업데이트
    };

    
  


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [amount, setAmount] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
  
    const handleOpenModal = () => {
      const allNotOpaque = imagesOpacity.every(opacity => opacity === false);
      if(allNotOpaque){
        alert("쿠폰을 선택하세요")
        return
      }
      setIsModalOpen(true);
    };
    const addComma = (price : any) =>{
      return Number(price).toLocaleString().toString()
    }
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
    useEffect(() => {
      const newPriceElements = Object.entries(counts).map(([price, count] :any, index) => (
        index === Object.entries(counts).length-1 ? (
        <span key={price}>{addComma(price)}원 X {count} = {addComma(totalAmount)}원</span> ) : (
          <span key={price}>{addComma(price)}원 X {count} + </span>
        )
      ));
      
      setPriceElements(newPriceElements);
    }, [counts]);

    useEffect(() => {
      const newCounts = priceList.reduce((acc :any, price : any) => {
        acc[price] = (acc[price] || 0) + 1;
        return acc;
      }, {});
      setCounts(newCounts);

    }, [priceList]); // priceList가 변경될 때마다 counts 상태를 업데이트
  
  
    // 예시로 사용할 초기 가격 데이터
  
    const handleSaveAmount = (amount:any) => {
      const newData = amount;
      const newImages = selectedImages.map((image,index) =>{
      if (imagesOpacity[index]) {
        priceList[index] = amount; // priceList의 해당 인덱스에 amount 할당
        priceList.sort((a,b)=>parseInt(a,10) - parseInt(b,10))

        // 빈도수를 기반으로 리스트 생성
      
        return { ...image, salesAmout: newData }; // 이미지 객체 수정
      } else {
        // 조건이 거짓일 경우, 이미지 객체 변경 없이 반환
        return image;
      }
      });
      const totalAmount = selectedImages.reduce((sum, image) => {
        console.log(image.salesAmout)
        return sum + parseInt(image.salesAmout, 10) || 0;
      }, 0);
      setTotalAmount(totalAmount)
      setSelectedImages(newImages);
      const resetImagesOpacity = imagesOpacity.map(() => false);
      setImagesOpacity(resetImagesOpacity);
      // 입력된 금액 로그 출력 (비동기적 상태 업데이트 후 확인을 위해 사용하지 않음)
      setAmount(amount); // 예시로 금액 상태를 업데이트
    };


    useEffect(() => {
      const totalAmount = selectedImages.reduce((sum, image) => {
        return sum + (parseInt(image.salesAmout, 10) || 0);
      }, 0);
      const newPriceList = selectedImages.map((image) => {
        return image.salesAmout
      });
      setpriceList(newPriceList)
      setTotalAmount(totalAmount);
    }, [selectedImages]); // selectedImages가 변경될 때마다 totalAmount를 재계산
    
    const loadImageHeight = (imageSrc : any) => new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img.height);
      img.onerror = reject;
      img.src = imageSrc;
    });
  
    // imageLists의 각 이미지에 대해 높이 로드
  
    const handleAddImages = async (event : any) => {
        let newImageLists :FileList = event.target.files;
        let imageLists = [...selectedImages];
        

        for (let i = 0; i < newImageLists.length; i++) {
          const currentImageUrl = URL.createObjectURL(newImageLists[i]);
          const currentImageName = newImageLists[i].name;
          const currentImage = newImageLists[i]
          console.log(imageNameDict)
          console.log(currentImageName)
          if(!imageNameDict.includes(currentImageName)){
            imageNameDict.push(currentImageName)
            imageLists.push({url : currentImageUrl, name :currentImageName, image:currentImage, salesAmout:"0"});
          }
          else{
            console.log("중복")
          }
        }

        const heightPromises = imageLists.map(image => loadImageHeight(image.url));
  
        // 모든 이미지 높이 로드 완료 대기
        const heights = await Promise.all(heightPromises);
      
        // 각 이미지 객체에 높이 정보 추가
        imageLists.forEach((image, index) => {
          image.height = heights[index];
        });
      
        // 높이 정보를 바탕으로 내림차순 정렬
        imageLists.sort((a, b) => b.height - a.height);
    
        
        setImageNameDict(imageNameDict)
        setSelectedImages(imageLists);
      };

      const handleDeleteImage = async(id:any) =>{
        const updatedImages = selectedImages.filter((image, index) => index !== id);
        const updatedOpacity = imagesOpacity.filter((opacity, index) => index !== id);
        const updatedImageNameDict = imageNameDict.filter((name,index)=>index!==id)
        setSelectedImages(updatedImages);
        setImagesOpacity(updatedOpacity);
        setImageNameDict(updatedImageNameDict)
      
      }



    return (
      <div >
      <div className="file-selection">

        <div style={{
          marginBottom : 10
        }}>
        <label htmlFor="input-file" className="btn-upload">
          <span className="btn-upload-text"> 파일 선택 </span>
          </label>
        <span className="file-count"> 파일 {imageNameDict.length}개</span>

        <input 
        type="file" 
        name="file" 
        id="input-file" 
        multiple accept="image/*" 
        className="hide"
        onChange={handleAddImages}/>
        </div>


        {
          selectedImages.length>0 ? (
            <div className="preview-image-list">
            {selectedImages.map((image, id) => (
          <div className="preview-image-wrap">
           <img key ={id}
                className={`preview-image ${imagesOpacity[id] ? 'opaque border-yellow' : ''}`} // 조건부 클래스 적용
                src={image.url}
                  alt={`${image.name}-${id}`}
                  onClick={()=>handleImageClick(id)} // 클릭 이벤트에 핸들러를 바인딩합니다.
                  />{
                  }

            <button onClick={()=>{
              handleDeleteImage(id)
            }}>삭제하기</button>
            <span>희망 판매 가격</span>
            <span>{parseInt(image.salesAmout).toLocaleString()}원</span>

            </div>            
            ))}
            </div>
          ):(
            <div className="file-preview" style={{
              display : "none"
            }}>
            <span className="file-preview-text"
            >상품 미리보기 필드</span> 
          </div>
  
          ) 
        }
        </div>
        {
          upLoading && 
          <div className={'modal-container'} ref={modalBackground} onClick={e => {
            if (e.target === modalBackground.current) {
              setModalOpen(false);
            }
          }}>
            <div className={'modal-content'}>
              <p>업로딩 중 </p>
            </div>
          </div>
  
        }
        <div className="button-field">
        <button className="button-send" onClick={handleOpenModal}>희망 금액 입력하기</button>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveAmount} />

        </div>
        <div>
          예상 정산 금액 : {priceElements}
          </div>


      </div>
    )
  }
export default ImagesSelector

  

{/* <div>
{selectedImages.map((image, id) => (
  <div key={id}>
    <img 
      className="preveiew-imgage"
      src={image.url}
      alt={`${image.name}-${id}`}
      style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }}
    />
    <p>{image.name}</p>
  </div>
))}
</div> */}
